<template>
  <div id="not-found-box">
    <h2>Ой, эта страница пропала.</h2>
    <h1>404</h1>
    <router-link to="/">на главную страницу</router-link>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
#not-found-box {
  width: 100%;
  height: 70vh;
  display: grid;
  place-content: center;
  text-align: center;
}
h2 {
  color: #0057b7;
}
h1 {
  font-size: 14rem;
  color: #0057b7;
}
a {
  text-decoration: none;
  color: #0057b7;
  transition: 0.2s ease;
}
a:hover {
  color: rgba(1, 106, 212, 0.8);
}
</style>
